import * as React from "react";

import { List, Datagrid, ReferenceField, TextField, FunctionField, TextInput } from 'react-admin';

import SendEmailsButton from './SendEmailsButton';

const filters = [
    <TextInput label="Search" source="q" alwaysOn />
];

const optionRenderer = choice => ( choice && choice.size ? `${choice.label} (${choice.size} People)` : '' );

export const EmailsList = (props) => (
    <List {...props} filters={filters} bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Subject" source="subject" />
            <ReferenceField label="Receipients" source="group_id" reference="groups">
                <FunctionField render={optionRenderer} />
            </ReferenceField>
            <SendEmailsButton />
        </Datagrid>
    </List>
);

export default EmailsList;
