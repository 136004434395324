import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@material-ui/core';

const DownloadAutoresponsePDFButton = ( props ) => {

    const handleClick = (e) => {
        e.preventDefault( );
        
        window.open( window.__rest_uri+'/v2-download-email-autoresponse/'+props.record.id+'?type=pdf&authToken='+localStorage.getItem( 'authToken' ), '_blank' );
    };

    return (
        <React.Fragment>
            <Button variant="contained" color="secondary" onClick={handleClick}>PDF</Button>
        </React.Fragment>
    );
}

DownloadAutoresponsePDFButton.propTypes = {
    record: PropTypes.object
};

export default connect(null, { })(DownloadAutoresponsePDFButton);
