import * as React from "react";

import { Show,
    List,
    TabbedShowLayout,
    Tab,
    Datagrid,
    ReferenceField,
    ReferenceManyField,
    FunctionField,
    ResourceContextProvider,
    TextField, 
    DateField,
    BooleanField,
    RichTextField,
    EditButton,
    DeleteButton,
    TopToolbar,
    ExportButton,
    Loading,
    useGetOne,
    sanitizeListRestProps,
    SimpleForm,
    AutocompleteInput,
    ReferenceInput,
    TextInput,
    SelectInput,
    FormDataConsumer,
    FilterButton,
    downloadCSV } 
    from 'react-admin';

import { Button, Box, Typography } from '@material-ui/core';
import { Add, EventSeat, ConfirmationNumber, MobileScreenShare } from '@material-ui/icons';

import PersonNameField from './PersonNameField';
import IssueTicketsButton from './IssueTicketsButton';
import ReissueTicketButton from './ReissueTicketButton';
import CheckinTicketButton from './CheckinTicketButton';
import BundlerLink from './BundlerLink';

import jsonExport from 'jsonexport/dist';

const EventTitle = ({ record }) => {
    return <span>{record ? `${record.name} ` : ''}</span>;
};

const ticketsFilters = ( props ) => {
    return [
            <TextInput label="Search" source="q" alwaysOn />,
            <TextInput label="Ticket Number" source="id" />,
            <TextInput label="Table" source="table_number" />,
            <ReferenceInput fullWidth label="Bundler" source="referral_person_id" reference="bundlers" filter={{ "event_id": props.id }}>
                <AutocompleteInput optionText={personOptionRenderer} />
            </ReferenceInput>,
            <ReferenceInput fullWidth label="Ticket Type" source="tickettype_id" reference="tickettypes" filter={{ "event_id": props.id }}>
                <SelectInput optionText="label" />
            </ReferenceInput>,
            <SelectInput label="Checked In" source="is_checked_in" choices={[
                { id: 'true', name: 'Yes' },
                { id: 'false', name: 'No' },
            ]} />,
        ];
}

const TicketTypeCreateButton = ( {record} ) => {
    const url = `#/tickettypes/create?source={"event_id":${record.id}}`;
    return ( <Button variant="outlined" href={url}><Add /> Create New Ticket Type</Button> );
};

const TicketsEmpty = (props) => {
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                There are no Ticketed Attendees yet!
            </Typography>
            <Typography variant="body1">
                Tickets will be automatically generated for Web Registrations, or you can create them manually.
            </Typography>
            <Button size="small" href={`#/tickets/create?source={"event_id":${props.id}}`}><ConfirmationNumber />&nbsp;&nbsp;New Ticket</Button>
        </Box>
    );
};

const TicketsListActions = (props) => {
    const {
        className,
        ...rest
    } = props;
    return ( <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button size="small" href={`#/tickets/create?source={"event_id":${props.id}}`}><ConfirmationNumber />&nbsp;&nbsp;New Ticket</Button>
        <Button size="small" href="" disabled><EventSeat />&nbsp;&nbsp;Assign Seats</Button>
        <IssueTicketsButton {...props} />
        <FilterButton />
        <ExportButton resource='tickets' maxResults='10000' />
    </TopToolbar> );
};

const TicketsExporter = tickets => {
    const ticketsForExport = tickets.map(ticket => {
        var ticketsForExport = {};
        ticketsForExport.id = ticket.id;
        ticketsForExport.event_name = ticket.event.name;
        ticketsForExport.tickettype_label = ticket.tickettype.label;
        ticketsForExport.tickettype_value = ticket.tickettype.value;
        ticketsForExport.person_id = ticket.person_id;
        ticketsForExport.person_hash = ticket.person.hash;
        ticketsForExport.person_first_name = ticket.person.first_name;
        ticketsForExport.person_middle_name = ticket.person.middle_name;
        ticketsForExport.person_last_name = ticket.person.last_name;
        ticketsForExport.person_email_address = ticket.person.email_address;
        ticketsForExport.person_phone_cell = ticket.person.phone_number;
        ticketsForExport.person_postal_code = ticket.person.postal_code;
        ticketsForExport.table_number = ticket.table_number;
        ticketsForExport.seat_number = ticket.seat_number;

        if ( null !== ticket.attributes )
        {
            var attributes = ticket.attributes;
            if ( "string" === typeof ticket.attributes )
                attributes = JSON.parse( ticket.attributes );

            if ( "object" === typeof attributes )
            {
                if ( attributes.hasOwnProperty( 'seating_requests' ) )
                    ticketsForExport.seating_requests = attributes.seating_requests;

                if ( attributes.hasOwnProperty( 'diet_restrictions' ) )
                    ticketsForExport.diet_restrictions = attributes.diet_restrictions;

                if ( attributes.hasOwnProperty( 'paid_by' ) )
                    ticketsForExport.paid_by = attributes.paid_by;

                if ( attributes.hasOwnProperty( 'is_attending' ) )
                    ticketsForExport.is_not_attending = attributes.is_attending ? 'YES' : 'NO';
            }
        }

        ticketsForExport.referral_person_id = ticket.referral_person_id;
        
        if ( null !== ticket.referral_person && ticket.referral_person.hasOwnProperty( 'first_name' ) )
            ticketsForExport.referral_person_name = ticket.referral_person.first_name+' '+ticket.referral_person.last_name;
        else
            ticketsForExport.referral_person_name = '';

        ticketsForExport.created_at = ticket.created_at;
        ticketsForExport.issued_at = ticket.issued_at;
        ticketsForExport.scanned_at = ticket.scanned_at;
        return ticketsForExport;
    });
    jsonExport(ticketsForExport, {
        headers: [
                'id',
                'event_name',
                'tickettype_label',
                'tickettype_value',
                'person_id',
                'person_hash',
                'person_first_name',
                'person_middle_name',
                'person_last_name',
                'person_email_address',
                'person_phone_cell',
                'person_postal_code',
                'table_number',
                'seat_number',
                'seating_requests',
                'diet_restrictions',
                'paid_by',
                'is_not_attending',
                'referral_person_id',
                'referral_person_name',
                'created_at',
                'issued_at',
                'scanned_at'
            ]
    }, (err, csv) => {
        downloadCSV(csv, 'tickets');
    });
};

const ScannersEmpty = (props) => {
    const { data, loading, error } = useGetOne( 'events', props.id );
    if (loading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                This Event has no Ticket Scanners!
            </Typography>
            <Button size="small" href={`#/scanners/create?source={"event_id":${props.id},"venue_id":${data.venue_id}}`}><MobileScreenShare />&nbsp;&nbsp;New Scanner</Button>
        </Box>
    );
};

const ScannersListActions = (props) => {
    const {
        className,
        ...rest
    } = props;
    const { data, loading, error } = useGetOne( 'events', props.id );
    if (loading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return ( <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button size="small" href={`#/scanners/create?source={"event_id":${data.id},"venue_id":${data.venue_id}}`}><MobileScreenShare />&nbsp;&nbsp;New Scanner</Button>
        <ExportButton resource='scanners' maxResults='100000' />
    </TopToolbar> );
};

const DownloadTicketButton = ( {record} ) => {
    const url = `${window.__rest_uri}/v2-issue-ticket/${record.id}?authToken=${localStorage.getItem( 'authToken' )}`;
    return ( <Button size="small" variant="outline" target="_blank" href={url}><ConfirmationNumber />&nbsp;&nbsp;Download</Button> );
};

const postOptionRenderer = choice => ( choice && choice.url ? `${choice.type}: ${choice.title} (${choice.url})` : '' );
const personOptionRenderer = choice => ( choice && choice.last_name ? `${choice.last_name}, ${choice.first_name} (${choice.email_address})` : '' );
const bundlerDefaultValue = () => {
    var filter = window.location.href.split( '?' ),
        post_id = null;

    if ( 1 < filter.length )
    {
        filter = filter[1];
    
        var params = new URLSearchParams( '?'+filter );

        if ( params.has( 'filter' ) )
        {
            var object = JSON.parse( params.get( 'filter' ) );

            if ( object.hasOwnProperty( 'post_id' ) )
                post_id = object.post_id;
        }
    }

    return { post_id: post_id, person_id: null, link: '?referral=' }; 
    };

const EventPanel = (props) => (
    <Show title={<EventTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="Summary">
                <TextField source="name" />
                <ReferenceField source="organization_id" reference="organizations" link="show">
                    <TextField source="label" />
                </ReferenceField>
                <ReferenceField source="venue_id" reference="venues" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <RichTextField source="description" />
                <DateField source="start_date" showTime options={{ timeZone: 'America/Toronto', dateStyle: 'long', timeStyle: 'short' }} />
                <DateField source="end_date" showTime options={{ timeZone: 'America/Toronto', dateStyle: 'long', timeStyle: 'short' }} />
            </Tab>
            <Tab label="Ticket Types">
                <TicketTypeCreateButton />
                <ReferenceManyField label="Ticket Types" reference="tickettypes" target="event_id">
                    <Datagrid>
                        <TextField label="Type Name" source="label" />
                        <TextField label="Price" source="value" />
                        <ReferenceField label="Assigned Room" source="room_id" reference="rooms">
                            <TextField source="name" />
                        </ReferenceField>
                        <BooleanField label="Available" source="is_active" />
                        <BooleanField label="Unlimited" source="is_exceedable" />
                        <EditButton />
                        <DeleteButton redirect={false} />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Bundler Links">
                <SimpleForm
                    initialValues={bundlerDefaultValue}
                    toolbar={null}
                    >
                    <ReferenceInput
                        fullWidth
                        label="Post (search by Title)"
                        source="post_id"
                        reference="posts">
                        <AutocompleteInput optionText={postOptionRenderer} />
                    </ReferenceInput>
                    <ReferenceInput fullWidth label="Person (search by Name, Email, Phone, or Postal Code)" source="person_id" reference="people">
                        <AutocompleteInput optionText={personOptionRenderer} />
                    </ReferenceInput>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => 0 < formData.post_id && 0 < formData.person_id && <BundlerLink record={formData} /> }
                    </FormDataConsumer>
                </SimpleForm>
                <ResourceContextProvider resource="bundlerlinks">
                    <List 
                        title="Bundler Links"
                        resource="bundlerlinks"
                        basePath="/bundlerlinks"
                        filter={{ event_id: props.id }}
                        syncWithLocation={false}>
                        <Datagrid>
                            <ReferenceField label="Attendee" sortBy="people.last_name" source="person_id" reference="people">
                                <PersonNameField />
                            </ReferenceField>
                            <TextField label="Submission Count" source="submission_count" />
                            <TextField label="Source URL" source="source_url" />
                        </Datagrid>
                    </List>
                </ResourceContextProvider>
                <br />
            </Tab>
            <Tab label="Attendees">
                <ResourceContextProvider resource="tickets">
                    <List 
                        title="Assigned Tickets"
                        resource="tickets"
                        basePath="/tickets"
                        filters={ticketsFilters(props)}
                        filter={{ event_id: props.id }}
                        sort={{ field: 'table_number', order: 'ASC' }}
                        syncWithLocation={false}
                        actions={<TicketsListActions {...props} />}
                        empty={<TicketsEmpty {...props} />}
                        exporter={TicketsExporter}>
                        <Datagrid>
                            <TextField label="Ticket Number" source="id" />
                            <ReferenceField label="Attendee" sortBy="people.last_name" source="person_id" reference="people">
                                <PersonNameField />
                            </ReferenceField>
                            <ReferenceField label="Ticket Type" source="ticket_id" reference="tickettypes">
                                <TextField source="label" />
                            </ReferenceField>
                            <TextField label="Table Number" source="table_number" />
                            <TextField label="Seat Number" source="seat_number" />
                            <ReferenceField label="Bundler" sortBy="bundler.last_name" source="referral_person_id" reference="people">
                                <PersonNameField />
                            </ReferenceField>
                            <FunctionField source="scanned_at" label="Checked In?" render={ (record,source) => 
                                <BooleanField record={{ ...record, scanned_at:null!==record.scanned_at }} source={source} /> } />
                            <DownloadTicketButton />
                            <ReissueTicketButton {...props} />
                            <CheckinTicketButton {...props} />
                            <EditButton />
                            <DeleteButton redirect={false} />
                        </Datagrid>
                    </List>
                </ResourceContextProvider>
                <br />
            </Tab>
            <Tab label="Scanning">
                <ResourceContextProvider resource="scanners">
                    <List 
                        title="Scanners"
                        resource="scanners"
                        basePath="/scanners"
                        filter={{ event_id: props.id }}
                        syncWithLocation={false}
                        actions={<ScannersListActions {...props} />}
                        empty={<ScannersEmpty {...props} />}>
                        <Datagrid>
                            <ReferenceField label="Scanner" source="person_id" reference="people">
                                <PersonNameField />
                            </ReferenceField>
                            <ReferenceField label="Ticket Type" source="ticket_id" reference="tickettypes">
                                <TextField source="label" />
                            </ReferenceField>
                            <ReferenceField label="Room" source="room_id" reference="rooms">
                                <TextField source="label" />
                            </ReferenceField>
                            <BooleanField label="Texted?" source="is_notified" />
                            <BooleanField label="Active?" source="is_active" />
                            <EditButton />
                            <DeleteButton redirect={false} />
                        </Datagrid>
                    </List>
                </ResourceContextProvider>
                <br />
            </Tab>
        </TabbedShowLayout>
    </Show>
);


export default EventPanel;
