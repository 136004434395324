import * as React from "react";

import { Edit, 
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    FormDataConsumer,
    SaveButton,
    Toolbar,
    required } from 'react-admin';

import { Typography, Box, Hidden } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import UnityEmailEditor from './Renderers/UnityEmailEditor';
import SendPreviewEmailButton from './SendPreviewEmailButton';

const useStyles = makeStyles({
    hiddenInput: {
        visibility: 'hidden',
        height: 0,
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
        border: 0
    },
    emailIFrame: {
        width: '100%',
        height: '700px',
        border: 'none'
    }
});

const EmailTitle = ({ record }) => {
    return <span>{record ? `${record.subject} ` : ''}</span>;
};

const optionRenderer = choice => ( choice && choice.size ? `${choice.label} (${choice.size} People)` : '' );

const IFrame = (props) => {
    const classes = useStyles( );
    return ( 0 < props.templateHTML.length && <iframe title="email-template-iframe" srcdoc={ JSON.parse( props.templateHTML ) } className={classes.emailIFrame} /> );
};

const EmailEditToolbar = props => (
    <Toolbar {...props} >
        <SendPreviewEmailButton label="Send Preview Email"/>
        { !props.record.is_sent && <SaveButton /> }
    </Toolbar>
);

const EmailEditor = (props) => {
    const classes = useStyles();

    return (
        <Edit title={<EmailTitle />} {...props}>
            <SimpleForm toolbar={<EmailEditToolbar />}>
                <FormDataConsumer>
                { ( { formData, rest } ) =>
                    formData.is_sent && <Box display="flex" fullWidth>
                        <Box flex={1}>
                            <MuiAlert elevation={6} variant="filled" severity="success">This Email was sent successfully!</MuiAlert>
                            <br />
                        </Box>
                    </Box>
                }
                </FormDataConsumer>
                <Typography variant="h6" fullWidth>Email Contents</Typography>
                <TextInput label="ID" source="id" fullWidth disabled />
                <Box display="flex" fullWidth>
                    <Box flex={1} mr="0.5em">
                        <ReferenceInput fullWidth label="Organization" source="organization_id" reference="organizations" validate={required()}>
                            <SelectInput optionText="label" />
                        </ReferenceInput>
                    </Box>
                    <Box flex={1} mr="0.5em">
                        <ReferenceInput fullWidth label="Group" source="group_id" reference="groups" validate={required()}>
                            <AutocompleteInput optionText={optionRenderer} />
                        </ReferenceInput>
                    </Box>
                </Box>
                <Box display="flex" fullWidth>
                    <Box flex={1} mr="0.5em">
                        <TextInput source="from_address" defaultValue={window.__default_email} disabled fullWidth validate={required()} />
                    </Box>
                    <Box flex={1} mr="0.5em">
                        <TextInput source="from_name" fullWidth validate={required()} />
                    </Box>
                </Box>
                <TextInput source="subject" fullWidth validate={required()} />
                <FormDataConsumer>
                { ( { formData, rest } ) =>
                    !formData.is_sent ? <UnityEmailEditor templateJson={formData.email_json} templateHTML={formData.email_html} {...rest} /> 
                    : <IFrame templateHTML={formData.email_html} />
                }
                </FormDataConsumer>
                <Hidden>
                    <TextInput source="email_json" type="hidden" label={false} className={classes.hiddenInput} />
                    <TextInput source="email_html" type="hidden" label={false} className={classes.hiddenInput} />
                </Hidden>
            </SimpleForm>
        </Edit>
    );
};

export default EmailEditor;
