import * as React from "react";

import { Create, 
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    FormDataConsumer,
    required } from 'react-admin';

import { Typography, Box, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import UnityEmailEditor from './Renderers/UnityEmailEditor';

const useStyles = makeStyles({
    hiddenInput: {
        visibility: 'hidden',
        height: 0,
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
        border: 0
    }
});

const EmailTitle = ({ record }) => {
    return <span>{record ? `${record.subject} ` : ''}</span>;
};

const optionRenderer = choice => ( choice && choice.size ? `${choice.label} (${choice.size} People)` : '' );

const EmailCreator = (props) => {
    const classes = useStyles();

    return (
        <Create title={<EmailTitle />} {...props}>
            <SimpleForm>
                <Typography variant="h6" fullWidth>Email Contents</Typography>
                <Box display="flex" fullWidth>
                    <Box flex={1} mr="0.5em">
                        <ReferenceInput fullWidth label="Organization" source="organization_id" reference="organizations" validate={required()}>
                            <SelectInput optionText="label" />
                        </ReferenceInput>
                    </Box>
                    <Box flex={1} mr="0.5em">
                        <ReferenceInput fullWidth label="Group" source="group_id" reference="groups" validate={required()}>
                            <AutocompleteInput optionText={optionRenderer} />
                        </ReferenceInput>
                    </Box>
                </Box>
                <Box display="flex" fullWidth>
                    <Box flex={1} mr="0.5em">
                        <TextInput source="from_address" defaultValue={window.__default_email} disabled fullWidth validate={required()} />
                    </Box>
                    <Box flex={1} mr="0.5em">
                        <TextInput source="from_name" fullWidth validate={required()} />
                    </Box>
                </Box>
                <TextInput source="subject" fullWidth validate={required()} />
                <FormDataConsumer>
                {({ formData, ...rest }) => <UnityEmailEditor templateJson={formData.email_json} templateHTML={formData.email_html} {...rest} />}
                </FormDataConsumer>
                <Hidden>
                    <TextInput source="email_json" type="hidden" label={false} className={classes.hiddenInput} />
                    <TextInput source="email_html" type="hidden" label={false} className={classes.hiddenInput} />
                </Hidden>
            </SimpleForm>
        </Create>
    );
};

export default EmailCreator;
