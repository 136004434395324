import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Typography
} from '@material-ui/core';

import { Send, Check } from '@material-ui/icons';

const SendPreviewEmailButton = ( props ) => {
    const [ open, setOpen ] = React.useState( false );
    const [ sent, setSent ] = React.useState( false );
    const [ value, setValue ] = React.useState( '' );

    const handleClick = ( e ) => {
        e.preventDefault( );
        setOpen( true );
        setValue( '' );
        setSent( false );

        return false;
    };

    const handleCancel = ( e ) => {
        e.preventDefault( );
        setOpen( false );

        return false;
    };

    const handleSubmit = (e) => {
        e.preventDefault( );
        
        fetch( 
            window.__rest_uri+'/v2-send-email-preview/'+props.record.id+'?override_email='+value, 
            { method: 'PUT', headers: { 'Content-Type': 'application/json', 'authorization': 'Basic '+localStorage.getItem( 'authToken' ) } } 
            )
                .then( ( data ) => data.json( ) )
                .then( function( data ) {
                    setSent( true );
                } );
    };

    return (
        <React.Fragment>
            <Button variant="contained" color="secondary" onClick={handleClick}><Send />&nbsp;&nbsp;Send Preview</Button>
            <Dialog open={open} onClose={handleCancel}>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Typography>
                            Previews will pick a random person from your database to replace merge fields only. Please allow for up to 15 minutes before trying to resend a preview email.
                        </Typography>
                        <TextField
                            fullWidth
                            label="Email Address to send Preview"
                            value={value}
                            type="email"
                            disabled={sent}
                            onChange={event => setValue(event.target.value)}
                            autoFocus
                        />
                    </DialogContent>
                    <DialogActions>
                        { !sent ? <Button type="submit"><Send />&nbsp;&nbsp;Send</Button> : <Button type="submit" disabled="true"><Check />&nbsp;&nbsp;Sent</Button> }
                        <Button onClick={handleCancel}>{ !sent ? 'Cancel' : 'Close' }</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    );
}

SendPreviewEmailButton.propTypes = {
    record: PropTypes.object
};

export default connect(null, { })(SendPreviewEmailButton);
