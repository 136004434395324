import * as React from "react";

import { List, Datagrid, TextField, FunctionField, TextInput, ReferenceInput, AutocompleteInput, ExportButton, FilterButton, TopToolbar, sanitizeListRestProps } from 'react-admin';

import renderPhoneNumber from './Renderers/renderPhoneNumber';

const filters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <ReferenceInput fullWidth label="Group" source="group_id" reference="groups">
        <AutocompleteInput optionText="label" />
    </ReferenceInput>,
];

const PeopleListActions = (props) => {
    const {
        className,
        ...rest
    } = props;
    return ( <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <FilterButton />
        <ExportButton resource='people' maxResults='100000' />
    </TopToolbar> );
};

export const PeopleList = (props) => (
    <List {...props} filters={filters} sort={{ field: 'last_name', order: 'ASC' }} actions={<PeopleListActions {...props} />} bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <TextField label="First Name" source="first_name" />
            <TextField label="Last Name" source="last_name" />
            <TextField label="Postal Code" source="postal_code" />
            <TextField label="Email Address" source="email_address" />
            <FunctionField label="Phone" render={ record => renderPhoneNumber(record.phone_number) } />
        </Datagrid>
    </List>
);

export default PeopleList;
