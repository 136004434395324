import * as React from 'react';
import { useGetMany, useGetOne } from 'react-admin';

import { TextField } from '@material-ui/core';

const BundlerLink = ( props ) => {
	const post = useGetMany( 'posts', [ props.record.post_id ] );
	const person = useGetOne( 'people', props.record.person_id );

	const onClick = ( e ) => {
		e.preventDefault( );
		if ( document.queryCommandSupported( 'copy' ) )
		{
			e.target.select( );
			document.execCommand( 'copy' );
		}
	};
	
	return ( <React.Fragment>
		<TextField
			label="Bundler Link"
			defaultValue={`${post.data[0].url}?referral=${person.data.hash}`}
			InputProps={{
				readOnly: true,
			}}
			onClick={onClick}
			fullWidth
		/>
	</React.Fragment> )
}

export default BundlerLink;
